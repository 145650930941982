import { AppointmentPreviewAdapter } from '@/plugins/schedule/scheduleCommon/preview/appointment_preview_adapter'
import Services from '@/services/service_repository_instance'
import { MODE_CLONE, MODE_MOVE } from '../const.js'
import formatter from '@/lib/formatters/formatter'
import { createStore } from '@/vue_components/store/store'
import { updateNote } from '@/plugins/schedule/scheduleCommon/preview/rest/rest'

const store = createStore()

Handlebars.registerHelper('if_and', function (a, b, opts) {
  if (a && b) {
    return opts.fn(this)
  }

  return opts.inverse(this)
})

const template = Handlebars.compile(
  `
  <div class="schedule-grid-preview-subcontainer">
    <div class="schedule-grid-preview-left">
      <div class="schedule-grid-preview-title-container">
        <a 
          href="clients/{{client.id}}" 
          target="_blank" 
          {{#if client.deleted_at}} class="deleted" {{/if}}
        >
          {{client.fullname}}
        </a>
        <br>
        ${t('reception.appointment_time')}: {{time}}-{{_endTime}}
        <br>
          <span>
            ${t('phone')}: 
            <span class="phone_number" data-client-id="{{client.id}}"> 
              {{#if client.phone}}
                  {{client.phone}}
              {{else}}
                  {{client.masked_phone}}
              {{/if}}
            </span>
          </span>
        <br>
        ${t('type')}: {{appointment_type}}
      </div>
      {{#if entry_types}}
        <ul class="schedule-grid-preview-entry_type-container">
        {{#each entry_types}}
          <li class="schedule-grid-preview-entry_type cut tooltip-bottom" title="{{title}}">
            {{title}}
          </li>
        {{/each}}
        </ul>
      {{/if}}

      {{!-- {{ Комментарий (note) }} --}}
      <div class="note-container note-show" id="note-container">
        <div class="note-text-wrapper">
          <span class="note-container__text" data-tooltip="{{note}}" id="note-text">{{note}}</span>
        </div>
        {{#if _currentUserCanManageAppointment}}
        <i class="fad fa-fw fa-pen note-container__edit-button" data-tooltip="${t('edit_comment')}"/>
        {{/if}}
      </div>
      
      {{!-- {{ Редактирование комментария (note) }} --}}
      <div class="note-edit-container" id="note-edit-container">
        <textarea class="note-edit-container__text">{{note}}</textarea>
        <div class="note-edit-container__buttons">
          <button type="button" class="btn btn-sm btn-warning" data-tooltip="${t('save')}" id="note-edit-save">
            <i class="fad fa-save" />
          </button>
          <button type="button" class="btn btn-sm btn-primary" data-tooltip="${t('cancel')}" id="note-edit-cancel">
            <i class="fad fa-times" />
          </button>
        </div>
      </div>

      <div class="schedule-grid-preview-button-container" id="preview-container">
        {{#if _hasOrder}}
          <button class="btn btn-primary btn-xs schedule-grid-appointment-go-to-order">
            <span class="fad fa-shopping-cart"></span>
            ${t('reception.go_to_order')}
          </button>
        {{else}}
          <button class="btn btn-primary btn-xs schedule-grid-appointment-create-order">
            <span class="fad fa-cart-plus"></span>
            ${t('reception.create_order')}
          </button>
        {{/if}}

          <button class="btn btn-primary btn-xs schedule-grid-appointment-edit">
            <span class="fad fa-pencil"></span>
            ${t('edit')}
          </button>
        {{#if _currentUserCanManageAppointment}}
          <button class="btn btn-primary btn-xs schedule-grid-appointment-move">
            <span class="fad fa-exchange-alt"></span>
            ${t('reception.re_make_appointment')}
          </button>
          <button class="btn btn-primary btn-xs schedule-grid-appointment-clone">
            <span class="fad fa-copy"></span>
            ${t('copy')}
          </button>
        {{/if}}
        {{#if_and _currentUserCanManageAppointment _currentUserCanManageWaitingList}}
          <button class="btn btn-primary btn-xs schedule-grid-appointment-move-to-waiting-list">
            <span class="fad fa-clipboard-list"></span>
            ${t('to_waiting_list')}
          </button>
        {{/if_and}}
        {{#if _currentUserCanManageAppointment}}
          <button class="btn btn-primary btn-xs schedule-grid-appointment-delete">
            <span class="fad fa-trash-alt"></span>
            ${t('delete')}
         </button>
        {{/if}}
      </div>
    </div>
    <div class="schedule-grid-preview-right">
      <div class="schedule-grid-preview-emk-container">
        <div class="schedule-grid-preview-emk">
          <a href="/medical_records/{{client.emk_id}}" target="_blank">
            <span>${t('emk')}: </span>
            {{client.emk_number}}
          </a>
        </div>
        <div class="schedule-grid-preview-client-groups tooltip-bottom cut" title="{{client.client_groups_string}}">
          {{client.client_groups_string}}
        </div>
        {{#if _canManageReminder}}
          <div class="schedule-grid-preview-reminder">
            <button class="btn btn-primary btn-xs add-reminder-with-client">
              <span class="fad fa-pen preview-icon"></span>
              <span class="preview-text">${t('task')}</span>
            </button>
          </div>
        {{/if}}
        <div class="schedule-grid-preview-last-appointments-container">
          <button class="btn btn-primary btn-xs schedule-grid-latest-appointments">
            <span class="fad fa-calendar-edit preview-icon"></span>
            <span class="preview-text">${t('records_appointments')}</span>
          </button>
        </div>
      </div>
      <div class="schedule-grid-preview-icons-container">
        <div id="AdministratorOverpopIcons"></div>
      </div>
      <div class="schedule-grid-preview-status-container">
        <div class="schedule-grid-preview-title-container">${t('status')}:</div>
        <select class="schedule-grid-appointment-status-select">
          <option value="need_approval">${t('enums.appointment.status.need_approval')}</option>
          {{#ifNotEqual appointment_source_id 1}}
            <option value="confirmed_by_administrator">${t('enums.appointment.status.confirmed_by_administrator')}</option>
          {{/ifNotEqual}}
          <option value="approved">${t('enums.appointment.status.approved')}</option>
          <option value="arrived">${t('enums.appointment.status.arrived')}</option>
          <option value="serviced">${t('enums.appointment.status.serviced')}</option>
          <option value="billed">${t('enums.appointment.status.billed')}</option>
          <option value="paid">${t('enums.appointment.status.paid')}</option>
          <option value="canceled">${t('enums.appointment.status.canceled')}</option>
          <option value="failed">${t('enums.appointment.status.failed')}</option>
        </select>
      </div>
    </div>
  </div>
  `
)

export default {
  genOverpopContent (_, appointment) {
    const appointmentTypes = store.getters.GET_APPOINTMENT_TYPES
    const previewAdapter = new AppointmentPreviewAdapter(appointment, appointmentTypes)
    const patchedPreview = previewAdapter.getWithAllPatchedFields()

    return template(Object.assign(patchedPreview, {
      _currentUserCanManageAppointment: Services.security.canManageAppointment,
      _currentUserCanManageWaitingList: Services.security.canManageWaitingList,
      _canManageReminder: Services.security.canManageReminder,
    }))
  },

  bindPreviewEvents (inst) {
    const god = this
    const preview = inst.$preview

    preview.on('click', '.schedule-grid-appointment-edit', () => {
      inst.$preview.overpop('hide')
      const params = Object.assign({}, inst.cache.activeAppointment)
      params._worktime = god._getWorkTime(inst, params.date, params.user_id, params._roughTime, params._endTime)
      if (typeof inst.options.onEdit === 'function') inst.options.onEdit(params)
    })

    preview.on('click', '.schedule-grid-appointment-move-to-waiting-list', () => {
      const app = inst.cache.activeAppointment
      const clientName = formatter.formatValue({
        ...app.client,
        secondName: app.client.second_name,
      }, ['user:name'])

      inst.options.confirmationModalCall(true, {
        headerMessage: T.transfer_appointment_to_the_waiting_list_head,
        message: T.transfer_appointment_to_the_waiting_list_body.format(clientName, app.user.short_name),
        yesEvent () {
          if (app.add_to_waiting_list) {
            Notificator.error(t('already_in_waiting_list'))
          } else {
            god._moveAppointmentsToWaitingList(inst, [app.id])
          }
        },
        noEvent () {
          inst.options.confirmationModalCall(false)
        },
      })
      inst.$preview.overpop('hide')
    })

    preview.on('click', '.schedule-grid-appointment-delete', () => {
      inst.$preview.overpop('hide')
      const app = inst.cache.activeAppointment
      let msgText = `${T.delete_fitting}?`

      if (app.add_to_waiting_list) {
        msgText = `${T.delete_fitting}? ${T.delete_depended_waiting_list}`
      }

      inst.options.confirmationModalCall(true, {
        headerMessage: T.deleting_an_appointment,
        message: msgText,
        yesEvent () {
          god._deleteAppointment(inst, app)
        },
        noEvent () {
          inst.options.confirmationModalCall(false)
        },
      })
    })

    preview.on('click', '.schedule-grid-appointment-move', () => {
      god._setMode(inst, MODE_MOVE)
      inst.$preview.overpop('hide')
      store.commit('SET_MODE_GRID_SCHEDULE', MODE_MOVE)
    })

    preview.on('click', '.schedule-grid-appointment-clone', () => {
      god._setMode(inst, MODE_CLONE)
      inst.$preview.overpop('hide')
      store.commit('SET_MODE_GRID_SCHEDULE', MODE_CLONE)
    })

    preview.on('click', '.schedule-grid-appointment-create-order', () => {
      const appointment = inst.cache.activeAppointment
      const clinicId = createStore().getters.GET_SCHEDULE_CURRENT_CLINIC_ID
      const url = Routes.new_order_path({
        client_id: appointment.client.id,
        clinic_id: clinicId,
        appointment_ids: appointment.id,
      })
      Turbolinks.visit(url)
    })

    preview.on('click', '.schedule-grid-appointment-go-to-order', () => {
      const appointment = inst.cache.activeAppointment
      Turbolinks.visit(Routes.order_path(appointment.order_id))
    })

    preview.on('click', '.add-reminder-with-client', () => {
      const appointment = inst.cache.activeAppointment
      inst.$preview.overpop('hide')
      inst.options.onReminder(appointment)
    })

    preview.on('click', '.schedule-grid-latest-appointments', () => {
      const onClientPreview = inst.options.onClientPreview
      if (typeof onClientPreview !== 'function') return

      const activeAppointment = inst.cache.activeAppointment
      if (activeAppointment) {
        onClientPreview(activeAppointment.client.id)
      }
      inst.$preview.overpop('hide')
    })

    /**
     * Показ/скрытие блоков
     * @param {Boolean} edit
     */
    const setNoteVisibility = (edit = false) => {
      const noteEditContainerStyle = preview.find('#note-edit-container')
      const noteShowContainerStyle = preview.find('#note-container')
      const previewContainerStyle = preview.find('#preview-container')

      if (edit) {
        noteEditContainerStyle.css({ display: 'flex' })
        noteShowContainerStyle.css({ display: 'none' })
        previewContainerStyle.css({ display: 'none' })

        return
      }

      noteEditContainerStyle.css({ display: 'none' })
      noteShowContainerStyle.css({ display: 'flex' })
      previewContainerStyle.css({ display: 'block' })

      Utils.updateTooltipsInstances(document.querySelector('.schedule-grid-preview'))
    }

    /**
     * Нажатие на редактирование комментария в overpop
     */
    preview.on('click', '.note-container__edit-button', () => {
      setNoteVisibility(true)
    })

    /**
     * Редактирование note - сохранить
     */
    preview.on('click', '#note-edit-save', () => {
      const noteText = preview.find('.note-edit-container__text').val()
      const appointmentId = inst.cache.activeAppointment.id
      const noteTextElement = $('#note-text')

      updateNote(appointmentId, noteText)
        .then((_) => {
          inst.cache.activeAppointment.note = noteText
          noteTextElement.text(noteText)
          noteTextElement.attr('data-tooltip', noteText)
          setNoteVisibility(false)
        })
        .catch((reason) => {
          const message = Utils.isPermissionError(reason)
            ? t('no_permission')
            : t('errors.save_error')
          Utils.reportError('administrator:updateNote', message)(reason)
        })
    })

    /**
     * Редактирование note - отмена
     */
    preview.on('click', '#note-edit-cancel', () => {
      preview.find('.note-edit-container__text').val($('#note-text').text())
      setNoteVisibility(false)
    })

    /**
     * Обновление тултипов при открытии overpop
     */
    PubSub.on('page.specific.overpop.show', () => {
      Utils.updateTooltipsInstances(document.querySelector('.schedule-grid-preview'))
    })
  },
}
